<template>
  <div id="container">
    <!-- <split-test> -->
      <!-- <BannerOne slot="A" chance="1" /> -->
      <!-- <BannerTwo slot="B" chance="1" /> -->
      <!-- <BannerThree slot="C" chance="1" /> -->
    <!-- </split-test>
     -->

    <!-- <BannerFour></BannerFour> -->
    <BannerOne v-bind:symbol="symbol"></BannerOne>

    <div class="run-section">
      <RunTheNumbers></RunTheNumbers>
    </div>

    <ForArtists v-bind:symbol="symbol"></ForArtists>

  </div>
</template>

<script>

import axios from "axios";
import BannerOne from '../components/banner/BannerOne.vue'
// import BannerTwo from '../components/banner/BannerTwo.vue'
// import BannerThree from '../components/banner/BannerThree.vue'
// import BannerFour from '../components/banner/BannerFour.vue'
import ForArtists from '../components/ForArtists.vue'

import RunTheNumbers from '../components/RunTheNumbers.vue'

export default {
  name: "Landing",
  components: {
    BannerOne,
    // BannerTwo,
    // BannerThree,
    // BannerFour,
    ForArtists,
    RunTheNumbers,
  },
  data() {
    return {
      symbol: "£",
    };
  },
  computed: {
    background_image () {
      const random_number = Math.floor(Math.random() * 3) + 1
      return '../assets/img/banner/background-0' + random_number.toString() + '.jpg'
    },
  },
  mounded() {
    axios.get("/onboarding/get-location").then((res) => {
      this.symbol = res.data.symbol;
      alert(this.symbol)
    });
  }

};
</script>

<style scoped>

@media (min-width: 576px) {

  .run-section {
    margin-top: -17em !important;
  }

}

@media (min-width: 768px) {

  .run-section {
    margin-top: -18em !important;
  }

}

@media (min-width: 768px) {

  .run-section {
    margin-top: -16.8em;
  }

}

@media (min-width: 992px) {

  .run-section {
    margin-top: -21.7em !important;
  }

}

@media (min-width: 1200px) {

  
}

@media (min-width: 1400px) {

  .run-section {
    margin-top: -21em !important;
  }

}

@media (min-width: 2000px) {

  .run-section {
    margin-top: -23em !important;
  }

}

@media (min-width: 2560px) {

}

</style>